export const CONNECTION_GAMES = [
  [
    {
      category: "Lyrics in the Happy Birthday Song",
      words: ["Happy", "To", "You", "Dear"],
      difficulty: 1,
    },
    {
      category: "____ Party",
      words: ["Surprise", "Conservative", "Birthday", "Bridal"],
      difficulty: 2,
    },
    {
      category: "Nicknames I Call You",
      words: ["Honeybun", "Sweetheart", "Bear", "Sweetiepie"],
      difficulty: 3,
    },

    {
      category: "Cottage Essentials",
      words: ["Fire", "Canoe", "Barbeque", "Buttertart"],
      difficulty: 4,
    },
  ],
];
